module MinGat.App.Public {
    export class LoginPageBodyViewModel {
        PageLinks: KnockoutObservableArray<any>;
        PageLinksDataSource: MinGat.WebApi.PageLinksDataSource;
        constructor() {
            var self = this;
            self.PageLinks = ko.observableArray();
            self.PageLinksDataSource = new MinGat.WebApi.PageLinksDataSource;
            var getPageLinksTask = self.PageLinksDataSource.GetPageLinksForPage("Global_Login");
            getPageLinksTask.then((links) => {
                self.PageLinks(links);
            });

        }
    }
} 